import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Layout } from '../components/Layout';
import { Heading } from '../components/Headings';
import { colors, fonts } from '../components/utilities/theme';
import { Icon } from '../components/svg/icon';
import { GatsbyImage } from '@utilities/gatsby-image';

const Vinyl = () => {
  const data = useStaticQuery(graphql`
    {
      first: file(relativePath: { eq: "vinyl-1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      second: file(relativePath: { eq: "vinyl-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Layout page="vinyl">
        <Grid>
          <FirstTitle behindText="heat applied">vinyl</FirstTitle>
          <SectionOneText>
            <Icon name="sport" />
            <Paragraph>
              We also offer cadcut vinyl and digital transfers, that give a
              sleeker, sportier look while maintaining durability.
            </Paragraph>
          </SectionOneText>
          <SectionOnePic
            fluid={data.first.childImageSharp.fluid}
            alt="shirt with pikes peak marthon and ascent logo applied using vinyl"
          />
          <SectionTwoPic
            fluid={data.second.childImageSharp.fluid}
            alt="patrick screen printing close-up"
          />
          <SectionTwoText>
            <Icon name="low-price" />
            <ParagraphThree>
              Vinyl or digital transfers are a great way to achieve a
              multi-colored look without breaking the bank. We've tested both
              products at length and both tend to outlast the garment!
            </ParagraphThree>
          </SectionTwoText>
        </Grid>
      </Layout>
    </motion.div>
  );
};

export default Vinyl;

const Grid = styled.div`
  justify-self: center;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  z-index: 10;
  margin: 15vh 0;
  row-gap: 20vh;
  align-items: center;
  grid-template-areas:
    'title title'
    'section-one-text section-one-pic'
    'section-two-pic section-two-text';

  @media (max-width: 1093px) {
    grid-template-columns: 1fr;
    justify-content: center;
    grid-template-areas:
      'title'
      'section-one-text'
      'section-one-pic'
      'section-two-text'
      'section-two-pic';
  }

  @media (max-width: 1093px) and (orientation: landscape) {
    margin: 30vh 0;
  }
`;

const SectionOneText = styled.section`
  grid-area: section-one-text;
  margin-right: 85px;
  display: grid;
  align-content: start;
  row-gap: 1em;
  justify-items: center;
  min-width: 500px;
  max-width: 600px;

  @media (max-width: 1093px) {
    margin-right: 0;
  }

  @media (max-width: 645px) {
    min-width: 0;
  }
`;

const Paragraph = styled.p`
  font-family: ${fonts.secondary};
  font-size: 24px;
  line-height: 1.75;
  max-width: 450px;
`;

const SectionOnePic = styled(Img)<GatsbyImage>`
  grid-area: section-one-pic;
  max-width: 600px;
  max-height: 500px;
  border-radius: 2px;
`;

const SectionTwoPic = styled(Img)<GatsbyImage>`
  grid-area: section-two-pic;
  max-width: 600px;
  max-height: 500px;
  border-radius: 2px;
`;

const SectionTwoText = styled.section`
  grid-area: section-two-text;
  width: 100%;
  padding: 35px;
  display: grid;
  align-content: start;
  row-gap: 1em;
  justify-items: center;
  min-height: 450px;
  max-width: 600px;

  @media (max-width: 645px) {
    min-width: 0;
  }
`;

const ParagraphTwo = styled(Paragraph)`
  color: white;
`;

const ParagraphThree = styled(ParagraphTwo)`
  color: ${colors.black};
`;

const FirstTitle = styled(Heading)`
  grid-area: title;
`;
